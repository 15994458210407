<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div
            v-for="(item, n) in actionsItems"
            :key="n"
            class="action-order add-task"
          >
            <!-- v-if="item.vistaRequerida < 0 || item.vistaRequerida === vistaActual" -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="item.variant"
              block
              :disabled="item.vistaRequerida > -1 && item.vistaRequerida !== vistaActual"
              @click="$emit(item.actionEmitter)"
            >
              <feather-icon
                v-if="item.icon"
                :icon="item.icon"
                class="mr-50"
              />
              <span class="align-middle">{{ item.title }}</span>
            </b-button>
          </div>
          <!-- <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('addPedido')"
            >
              Add Order
            </b-button>
          </div>
          <div class="action-order add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              block
              @click="$emit('selectAll')"
            >
              Select all
            </b-button>
          </div>
          <div class="action-order add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              block
              @click="$emit('deselectAll')"
            >
              Unselect all
            </b-button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },
  props: {
    vistaActual: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const actionsItems = [
      {
        title: 'Add Order',
        variant: 'primary',
        actionEmitter: 'addPedido',
        vistaRequerida: 1,
      },
      {
        title: 'Add Route',
        variant: 'primary',
        actionEmitter: 'addRuta',
        vistaRequerida: 2,
      },
      {
        title: 'Select All',
        variant: 'outline-primary',
        actionEmitter: 'selectAll',
        vistaRequerida: -1,
      },
      {
        title: 'Unselect all',
        variant: 'outline-primary',
        actionEmitter: 'deselectAll',
        vistaRequerida: -1,
      },
      {
        title: 'Pre-Calcular Rutas',
        variant: 'outline-warning',
        actionEmitter: 'preCalculaRutas',
        vistaRequerida: 1,
      },
      {
        title: 'Optimizar Ruta',
        variant: 'outline-danger',
        actionEmitter: 'calculaRutas',
        vistaRequerida: 2,
      },
      {
        title: 'Asignar Ruta',
        variant: 'outline-primary',
        actionEmitter: 'pickingToRutas',
        vistaRequerida: 1,
      },
      {
        title: 'Des-Asignar Ruta',
        variant: 'outline-primary',
        actionEmitter: 'pickingToRutasOff',
        vistaRequerida: 1,
      },
      {
        title: 'Export',
        variant: 'outline-primary',
        actionEmitter: 'exportToExcel',
        vistaRequerida: 1,
        icon: 'DownloadIcon',
      },
      {
        title: 'Export Routes',
        variant: 'outline-primary',
        actionEmitter: 'exportRutasToExcel',
        vistaRequerida: 2,
        icon: 'DownloadIcon',
      },
    ]

    return {
      actionsItems,
    }
  },
}
</script>

<style lang="scss" scoped>
.action-order {
  padding-top: 0.5rem !important;
}
.todo-sidebar {
  background-color: transparent !important;
  width: 190px !important;
}
</style>
