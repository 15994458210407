<template>
  <b-modal
    id="ticketPrint"
    ref="ticket-print"
    :visible="isTicketPrint"
    :title="`Print Ticket Pedido ${pedido.id}`"
    ok-title="Imprimir"
    cancel-variant="outline-secondary"
    hide-footer
    centered
    size="sm"
    @change="(val) => $emit('update:is-ticket-print', val)"
    @ok="printerTicket"
  >
    <div class="ticket">
      <svg
        class="rectangulo"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 210 400"
        width="210"
        height="400"
      >
        <rect
          x="0"
          y="0"
          width="210"
          height="400"
          style="fill: none; stroke: black"
        />
      </svg>
      <p class="centrado">
        PRUEBA DE IMPRESIÓN
        <br>
        Dirección completa del domicilio del cliente final
        <br>
        <br>
        21/4/2021 14:00
      </p>
      <p class="centrado">
        PEDIDO: L01
      </p>
    </div>
    <button
      class="oculto-impresion"
      @click="printerTicket"
    >
      Imprimir
    </button>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    // BaseCheck,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: 'isTicketPrint',
    event: 'update:is-ticket-print',
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
    isTicketPrint: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  methods: {
    printerTicket() {
      window.print()
    },
  },
}
</script>

<style scoped>
* {
  font-size: 12px;
  font-family: "Times New Roman";
  margin: 0;
  padding: 0;
}

.rectangulo {
  position: absolute;
}

td,
th,
tr,
table {
  border-top: 1px solid black;
  border-collapse: collapse;
}

td.producto,
th.producto {
  width: 75px;
  max-width: 75px;
}

td.cantidad,
th.cantidad {
  width: 40px;
  max-width: 40px;
  word-break: break-all;
}

td.precio,
th.precio {
  width: 40px;
  max-width: 40px;
  word-break: break-all;
}

.centrado {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 210px;
  max-width: 210px;
  height: 600px;
}

.logo {
  max-width: inherit;
  width: inherit;
}

@media print {

  .oculto-impresion,
  .oculto-impresion * {
    display: none !important;
  }
}
</style>
