<template>
  <b-modal
    id="pickingVehiculo"
    ref="picking-vehiculo"
    :visible="isPickingVehiculo"
    :title="`Picking Vehículo ${pedido.id} | ${pedido.cliente_nombre} | ${pedido.bultos} Bultos`"
    ok-title="Guardar"
    cancel-variant="outline-secondary"
    hide-footer
    centered
    size="lg"
    @change="(val) => $emit('update:is-picking-vehiculo', val)"
    @ok="saveChanges"
  >
    <b-row>
      <b-col md="6">
        <h6>Selecciona una Ruta</h6>
        <b-card-text>
          <span>Si no hay ninguna disponible, puedes agregar una nueva.</span>
        </b-card-text>

        <b-form-group>
          <v-select
            v-model="rutaSelected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="rutasOption"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal,
  BRow, BCol, BFormGroup, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // BButton,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isPickingVehiculo',
    event: 'update:is-picking-vehiculo',
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
    rutas: {
      type: Array,
      required: true,
    },
    isPickingVehiculo: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      rutaSelected: null,
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
      pedidoEdit: null,
      productosEdit: null,
      productsChecked: [],
      productsAllRef: [],
    }
  },
  computed: {
    vehiculos() {
      return this.$store.state.vehiculos.vehiculos
    },
    rutasOption() {
      if (!this.rutas.length) return []
      return this.rutas.map(r => ({ title: `${r.vehiculo_tipo} - ${r.nombre}`, value: r.id }))
    },
  },
  watch: {
    pedido() {
      this.initialize()
      this.fetchVehiculos()
    },
  },
  methods: {
    initialize() {
      this.rutaSelected = null
      if (this.pedido && this.pedido.rutaSelected) this.rutaSelected = this.pedido.rutaSelected
    },
    fetchVehiculos() {
      this.$store.dispatch('vehiculos/getVehiculos')
    },
    saveChanges() {
      //
    },
    formSubmitted() {
      this.saveChanges()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
