<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <!-- <button @click="test">
      Test
    </button> -->
    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Search"
              @input="updateSearchQuery"
            />
          </b-input-group>
        </div>
      </div>

      <filtros
        @updateZonaFilter="updateZonaFilter"
        @updateRutaFilter="updateRutaFilter"
      />

      <div class="todo-task-list-wrapper list-group">
        <b-tabs v-model="tabIndex">
          <!-- Mapa -->
          <b-tab
            title="Mapa"
          >
            <h5>Mapa</h5>
          </b-tab>

          <!-- Picked -->
          <b-tab
            active
            :title="$route.meta.view"
          >
            <pedidos-content
              :pedidos-selected="pedidosSelected"
              :ruta-filter="rutaFilter || ''"
              :zona-filter="zonaFilter || ''"
              :search-query="searchQuery ||''"
              @updatePedidosSelected="updatePedidosSelected"
              @editPedido="editPedido"
              @pickingVehiculo="pickingVehiculo"
              @picking="picking"
              @updateMapView="updateMapView"
              @updateMostrarOrden="updateMostrarOrden"
            />
          </b-tab>

          <!-- Rutas -->
          <b-tab
            title="Rutas"
          >
            <rutas-content
              :rutas-selected="rutasSelected"
              @updateRutasSelected="updateRutasSelected"
              @editRuta="editRuta"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <pedidos-left-sidebar
        :vista-actual="tabIndex"
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @addPedido="addPedido"
        @addRuta="addRuta"
        @selectAll="selectAll"
        @deselectAll="deselectAll"
        @preCalculaRutas="preCalculaRutas"
        @calculaRutas="calculaRutas"
        @pickingToRutas="pickingToRutas"
        @pickingToRutasOff="pickingToRutasOff"
        @exportToExcel="exportToExcel"
        @exportRutasToExcel="exportRutasToExcel"
      />
    </portal>

    <ruta-add-new
      :is-add-new-ruta-sidebar-active.sync="isAddNewRutaSidebarActive"
      :idruta="new Date().getTime()"
    />

    <pedido-add-new
      :is-add-new-pedido-sidebar-active.sync="isAddNewPedidoSidebarActive"
    />

    <pedido-edit
      :pedido="pedidoSelect"
      :is-edit-pedido.sync="isEditPedido"
    />

    <ruta-edit
      :ruta="rutaSelect"
      :is-edit-ruta.sync="isEditRuta"
    />

    <pedido-picking
      :pedido="pedidoSelect"
      :is-picking-pedido.sync="isPickingPedido"
    />

    <ticket-print
      :pedido="pedidoSelect"
      :is-ticket-print="isTicketPrint"
    />

    <picking-vehiculo
      :pedido="pedidoSelect"
      :rutas="rutas"
      :is-picking-vehiculo.sync="isPickingVehiculo"
    />

    <picking-pedidos-to-rutas
      :pedidos="pedidosSelected"
      :is-picking-pedidos-to-rutas.sync="isPickingPedidosToRutas"
      @cierraVentana="isPickingPedidosToRutas = false"
    />

    <mapa-picking
      :markers="itemsToMap"
      :path="overviewPath"
      :pre="true"
      :markers-select-by-picking="selectedToMarkers"
      @selectedByMarker="selectedByMarker"
    />
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import {
  ref,
} from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import PedidoAddNew from '@/views/bimbo/components/PedidoAddNewR1.vue'
import RutaAddNew from '@/views/bimbo/components/RutaAddNew.vue'
import PedidoEdit from '@/views/bimbo/components/PedidoEdit.vue'
import RutaEdit from '@/views/bimbo/components/RutaEdit.vue'
import PedidoPicking from '@/views/bimbo/components/PedidoPicking.vue'
import TicketPrint from '@/views/bimbo/components/TicketPrint.vue'
import PickingVehiculo from '@/views/bimbo/components/PickingVehiculo.vue'
import PickingPedidosToRutas from '@/views/bimbo/components/PickingPedidosToRutas.vue'
import MapaPicking from '@/views/bimbo/components/MapaPicking.vue'
import PedidosLeftSidebar from './PedidosLeftSidebar.vue'
import PedidosContent from './PedidosContent.vue'
import RutasContent from './RutasContent.vue'
import Filtros from './Filtros.vue'
import usePedidos from './usePedidos'

export default {
  components: {
    BTabs,
    BTab,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

    PedidosLeftSidebar,
    PedidosContent,
    RutasContent,
    PedidoAddNew,
    RutaAddNew,
    PedidoEdit,
    RutaEdit,
    PedidoPicking,
    TicketPrint,
    PickingVehiculo,
    PickingPedidosToRutas,
    MapaPicking,
    // Filtros
    Filtros,
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const isTaskHandlerSidebarActive = ref(true)
    const tabIndex = ref(1)

    const isTicketPrint = ref(false)

    const {
      fetchClientes,
      fetchPedidos,
      fetchProductos,
      fetchRutas,
      fetchVehiculos,
      itemsToMap,
      overviewPath,
      selectedToMarkers,
      selectedByMarker,
      pedidosSelected,
      updatePedidosSelected,
      // Nuevo Pedido
      isAddNewPedidoSidebarActive,
      // Edit Pedido
      pedidoSelect,
      isEditPedido,
      editPedido,
      // Picking Pedido
      isPickingPedido,
      picking,
      pickingVehiculo,
      rutas,
      isPickingVehiculo,
      isPickingPedidosToRutas,
      addPedido,
      rutasSelected,
      updateRutasSelected,
      updateMapView,
      updateMostrarOrden,
      itemsMostrarOrden,
      updateRutaFilter,
      rutaFilter,
      updateZonaFilter,
      zonaFilter,
      searchQuery,
      updateSearchQuery,
      selectPedidosAll,
      selectRutasAll,
      isAddNewRutaSidebarActive,
      preCalculaRutas,
      calculaRutas,
      pickingToRutas,
      pickingToRutasOff,
      exportToExcel,
      exportRutasToExcel,
      fechaDesde,
      fechaHasta,
      test,
      rutaSelect,
      isEditRuta,
      editRuta,
    } = usePedidos()

    const deselectAll = () => {
      if (tabIndex.value < 2) {
        selectPedidosAll(false)
        return
      }
      selectRutasAll(false)
    }

    const selectAll = () => {
      // Selección de pedidos
      if (tabIndex.value < 2) {
        selectPedidosAll(true)
        return
      }
      selectRutasAll(true)
    }

    const addRuta = () => {
      isAddNewRutaSidebarActive.value = true
    }

    // const preCalculaRutas = () => {
    //   console.log('preCalculaRutas')
    // }

    return {
      isTaskHandlerSidebarActive,
      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      tabIndex,

      fetchClientes,
      fetchPedidos,
      fetchProductos,
      fetchRutas,
      fetchVehiculos,
      // Mapa
      itemsToMap,
      overviewPath,
      selectedToMarkers,
      selectedByMarker,
      pedidosSelected,
      updatePedidosSelected,
      // Nuevo Pedido
      isAddNewPedidoSidebarActive,
      // Nueva Ruta
      isAddNewRutaSidebarActive,
      // Edit Pedido
      pedidoSelect,
      isEditPedido,
      editPedido,
      // Picking Pedido
      isPickingPedido,
      isTicketPrint,
      picking,
      pickingVehiculo,
      rutas,
      isPickingVehiculo,
      isPickingPedidosToRutas,
      addPedido,
      rutasSelected,
      updateRutasSelected,
      updateMapView,
      updateMostrarOrden,
      itemsMostrarOrden,
      updateRutaFilter,
      rutaFilter,
      updateZonaFilter,
      zonaFilter,
      searchQuery,
      updateSearchQuery,
      deselectAll,
      selectAll,
      addRuta,
      // Cálculos
      preCalculaRutas,
      calculaRutas,
      pickingToRutas,
      pickingToRutasOff,
      exportToExcel,
      exportRutasToExcel,
      fechaDesde,
      fechaHasta,
      test,
      rutaSelect,
      isEditRuta,
      editRuta,
    }
  },

  watch: {
    fechaDesde(newValue) {
      console.log('Cambios DESDE recibidos en Pedidos All', newValue)
      this.fetchPedidos()
      this.fetchRutas()
    },
    fechaHasta(newValue) {
      console.log('Cambios HASTA recibidos en Pedidos All', newValue)
      this.fetchPedidos()
      this.fetchRutas()
    },
  },

  created() {
    this.fetchPedidos()
    this.fetchRutas()
    this.fetchClientes()
    this.fetchVehiculos()
    this.fetchProductos()
  },

  methods: {
    // updatePedidosSelected(data) {
    //   console.log(data)
    // },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
.content-area-wrapper {
  overflow: inherit !important;
  border: none !important;
  height: inherit !important;
}
.todo-application .content-area-wrapper .content-right {
  border-left: none !important;
  height: fit-content !important;
}
.todo-task-list-wrapper {
    height: fit-content !important;
    background-color:transparent !important;
}
</style>
