<template>
  <b-card-actions
    title="Orders"
    class="mb-0"
    action-collapse
  >
    <div class="mb-1">
      <label>Show</label>
      <v-select
        v-model="perPage"
        :options="perPageOptions"
        :clearable="false"
        class="per-page-selector d-inline-block mx-50"
      />
      <label>entries of {{ itemsMostrarOrden.total }}</label>
    </div>
    <b-table
      id="listPedidosTable"
      ref="tableListPedidos"
      class="position-relative"
      :items="itemsMostrarOrden.pedidos"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      table-variant="none"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- Column: select -->
      <template #cell(select)="data">
        <b-form-checkbox
          :checked="isCheckedOrder(data.item.id)"
          @click.native.stop
          @change="$emit('updatePedidosSelected', data.item.id)"
        />
      </template>

      <!-- Column: Pedido -->
      <template #cell(pedido)="data">
        <b-media vertical-align="center">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="$emit('editPedido', data.item)"
          >
            {{ splitedPedido(data.item.id) }}
          </b-link>
          <small class="text-muted">{{ data.item.fechaIso }}</small>
        </b-media>
      </template>

      <!-- Column: Cliente -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <b-link
            :to="{ name: 'bimbo-clientes-edit', params: { id: data.item.cliente_id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.cliente_id }}
          </b-link>
          <small class="text-muted">{{ data.item.cliente_nombre }}</small>
        </b-media>
      </template>

      <!-- Column: zona -->
      <template #cell(cliente_ruta)="data">
        <div class="text-nowrap">
          <span class="text-right font-weight-bolder">{{ data.item.cliente_ruta }}</span>
        </div>
      </template>

      <!-- Column: ruta -->
      <template #cell(ruta)="data">
        <div
          class="text-nowrap"
          @click="$emit('pickingVehiculo', data.item)"
        >
          <vehiculo-icon
            :color="rutaColorIcon(data.item.ruta)"
            size="36"
          />
        </div>
      </template>

      <!-- Column: Status -->
      <!-- <template #cell(status)="data">
        <b-button
          v-if="data.item.status && !data.item.is_picking_completed"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-warning"
          class="btn-icon"
          size="sm"
        >
          <feather-icon
            icon="AlertTriangleIcon"
            size="8"
          />
        </b-button>
        <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant(data.item.status).color}`"
          class="text-capitalize"
        >
          {{ resolveUserStatusVariant(data.item.status).txt }}
        </b-badge>
      </template> -->

      <template #cell(status)="data">
        <b-media vertical-align="center">
          <b-button
            v-if="data.item.status && !data.item.is_picking_completed"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-warning"
            class="btn-icon"
            size="sm"
          >
            <feather-icon
              icon="AlertTriangleIcon"
              size="8"
            />
          </b-button>
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status).color}`"
            class="text-capitalize"
          >
            {{ resolveUserStatusVariant(data.item.status).txt }}
          </b-badge>
          <small class="text-muted">{{ data.item.fechaEntrega }}</small>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="data.item.status ? 'flat-success' : 'flat-secondary'"
            class="btn-icon"
            size="sm"
            :disabled="data.item.is_picking_completed"
            @click="$emit('picking', data.item)"
          >
            <feather-icon
              :id="`order-row-${data.item.id}-cart-icon`"
              icon="ShoppingCartIcon"
              class="cursor-pointer"
              :color="data.item.status > 1 ? '#ffebeb' : ''"
              size="16"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="data.item.status ? 'flat-success' : 'flat-secondary'"
            class="btn-icon"
            size="sm"
            @click="$router.push({ name: 'bimbo-pedidos-ticket', params: { id: data.item.id } })"
          >
            <feather-icon
              :id="`order-row-${data.item.id}-print-icon`"
              icon="PrinterIcon"
              class="cursor-pointer"
              size="16"
            />
          </b-button>
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'bimbo-pedidos-ticket', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Print</span>
            </b-dropdown-item>

            <b-dropdown-item @click="$emit('editPedido', data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>

    <!-- Paginación -->
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="itemsMostrarOrden.total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card-actions>

</template>

<script>
// import preRutaFacke from '@/@fake-db/data/pre_ruta.json'
// import rutasOptimizadasFack from '@/@fake-db/data/rutas_optimizadas.json'
// import rutasSelectOptimizarFack from '@/@fake-db/data/rutas_select_optimizar.json'
import {
  BRow, BCol, BButton, BTable,
  BDropdown, BDropdownItem, BPagination, BMedia, BLink, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VehiculoIcon from '@/assets/images/icons/maps/VehiculoIcon.vue'
import usePedidos from './usePedidos'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BFormCheckbox,
    BCardActions,
    vSelect,
    // BTooltip,
    // BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VehiculoIcon,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    pedidosSelected: {
      type: Array,
      required: true,
    },
    rutaFilter: {
      type: String,
      required: true,
    },
    zonaFilter: {
      type: String,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      itemsMostrarOrden,
      itemsToMap,
      tableColumns,
      sortBy,
      isSortDirDesc,
      perPage,
      perPageOptions,
      currentPage,
      // pedidosSelected,
      // editPedido,
      // pickingVehiculo,
      rutaColorIcon,
      resolveUserStatusVariant,
      updateRutaFilter,
      updateZonaFilter,
      updateSearchQuery,
      // pintaIndexPedidosControl,
      pedidosControlIndex,
      // picking,
    } = usePedidos()

    const isCheckedOrder = pedidoId => props.pedidosSelected.includes(pedidoId)
    const prePedido = pedidoId => pedidosControlIndex.value.filter(p => p.id === pedidoId).map(i => i.txt)[0]
    const splitedPedido = pedidoId => pedidoId.split('R')[1]

    return {
      itemsMostrarOrden,
      itemsToMap,
      tableColumns,
      sortBy,
      isSortDirDesc,
      perPage,
      perPageOptions,
      currentPage,
      // pedidosSelected,
      // editPedido,
      // pickingVehiculo,
      rutaColorIcon,
      resolveUserStatusVariant,
      updateRutaFilter,
      updateZonaFilter,
      updateSearchQuery,
      // picking,
      isCheckedOrder,
      // pintaIndexPedidosControl,
      pedidosControlIndex,
      prePedido,
      splitedPedido,
    }
  },

  watch: {
    rutaFilter(newValue) {
      this.updateRutaFilter(newValue)
    },
    zonaFilter(newValue) {
      this.updateZonaFilter(newValue)
    },
    searchQuery(newValue) {
      this.updateSearchQuery(newValue)
    },
    pedidosSelected() {
      this.$root.$emit('bv::refresh::table', 'listPedidosTable')
    },
    // itemsToMap(newValue) {
    //   this.$emit('updateMapView', newValue)
    // },
    itemsMostrarOrden(newValue) {
      this.$emit('updateMostrarOrden', newValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
