import { render, staticRenderFns } from "./RutasContent.vue?vue&type=template&id=0f2623de&scoped=true&"
import script from "./RutasContent.vue?vue&type=script&lang=js&"
export * from "./RutasContent.vue?vue&type=script&lang=js&"
import style0 from "./RutasContent.vue?vue&type=style&index=0&id=0f2623de&lang=scss&scoped=true&"
import style1 from "./RutasContent.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f2623de",
  null
  
)

export default component.exports