<template>
  <b-modal
    id="editaRuta"
    ref="edit-ruta"
    :visible="isEditRuta"
    :title="`Edición de Ruta ${ruta.id}`"
    ok-title="Guardar"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    hide-footer
    @change="(val) => $emit('update:is-edit-ruta', val)"
  >
    <b-form
      v-if="newRuta"
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-form-group
        label="Vehículo"
        label-for="rutaSelected"
      >
        <v-select
          v-model="selectVehiculo"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="id"
          :options="vehiculos"
          :clearable="false"
          input-id="rutaSelected"
        />
      </b-form-group>

      <b-form-group
        label="Rider"
        label-for="riderSelected"
      >
        <v-select
          v-model="selectRider"
          label="nombre"
          :options="riders"
          :clearable="false"
          input-id="riderSelected"
        />
      </b-form-group>

      <b-form-group
        label="Nombre Ruta"
        label-for="rutaName"
      >
        <b-form-input
          id="rutaName"
          v-model="newRuta.nombre"
          placeholder="Nombre de la ruta"
          maxlength="24"
        />
      </b-form-group>

      <!-- <div>
          <label for="colorSelect">Color</label>
          <input
            id="colorSelect"
            v-model="newRuta.color"
            type="color"
          >
        </div> -->

      <b-form-group
        label="Color"
        label-for="colorSelect"
      >
        <b-form-input
          id="colorSelect"
          v-model="newRuta.color"
          type="color"
        />
      </b-form-group>

      <b-form-group
        label="Descripción"
        label-for="rutaDescription"
      >
        <b-form-input
          id="rutaDescription"
          v-model="newRuta.description"
          placeholder="(Opcional) Una descripción de la ruta"
          maxlength="150"
        />
      </b-form-group>

      <div>
        <label for="rutaNotas">Notas</label>
        <b-form-textarea
          id="rutaNotas"
          v-model="newRuta.notas"
          placeholder="(Opcional) Notas sobre esta ruta"
          rows="3"
        />
      </div>

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          :disabled="!isPermitSave"
          @click="onSave"
        >
          Guardar
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BForm, BFormInput, BFormGroup, BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    // BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormTextarea,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isEditRuta',
    event: 'update:is-edit-ruta',
  },
  props: {
    ruta: {
      type: Object,
      required: true,
    },
    isEditRuta: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      // required,
      // alphaNum,
      newRuta: null,
      selectVehiculo: null,
      selectRider: null,
    }
  },
  computed: {
    riders() {
      return [
        {
          id: 'hq3v5ellzlZ0sfOH1qL0kfWfz8B2',
          nombre: 'Rider 1',
        },
        {
          id: 'amwYAHaUz6cAiQqq3yxNpeMx6Nz1',
          nombre: 'Rider 2',
        },
        {
          id: 'G36CsnCrfXeNjKmDZVOKptN7vE33',
          nombre: 'Rider 3',
        },
      ]
    },
    isPermitSave() {
      let result = false
      if (this.selectVehiculo && this.newRuta && this.newRuta?.nombre) result = true
      return result
    },
    vehiculos() {
      return this.$store.state.vehiculos.vehiculos
    },
    idruta() {
      if (!this.ruta) return null
      return this.ruta.id
    },
  },
  watch: {
    idruta() {
      this.initialize()
    },
  },
  methods: {
    initialize() {
      this.resetForm()
      this.newRuta = JSON.parse(JSON.stringify(this.ruta))
      const vh = this.vehiculos.filter(v => v.id === this.newRuta.vehiculo_id)[0]
      this.selectVehiculo = vh
      const rd = this.riders.filter(r => r.id === this.newRuta.rider_id)[0]
      this.selectRider = rd
    },
    onSave() {
      this.newRuta.vehiculo_id = this.selectVehiculo.id
      this.newRuta.vehiculo_tipo = this.selectVehiculo.nombre
      this.newRuta.vehiculo_capacidad = this.selectVehiculo.capacidadUnidadesTransporte
      this.newRuta.rider_id = this.selectRider.id
      this.newRuta.rider_nombre = this.selectRider.nombre
      this.$store.dispatch('rutas/addRuta', this.newRuta)
      // this.$emit('update:is-edit-ruta', false)
    },
    resetForm() {
      this.newRuta = null
      this.selectVehiculo = null
    },
    onSubmit() {},
  },
}
</script>

<style lang="scss" scoped>

</style>
