<template>
  <b-sidebar
    id="add-new-ruta-sidebar"
    :visible="isAddNewRutaSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-ruta-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Route
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        v-if="newRuta"
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-form-group
          label="Vehículo"
          label-for="rutaSelected"
        >
          <v-select
            v-model="selectVehiculo"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="id"
            :options="vehiculos"
            :clearable="false"
            input-id="rutaSelected"
          />
        </b-form-group>

        <b-form-group
          label="Rider"
          label-for="riderSelected"
        >
          <v-select
            v-model="selectRider"
            label="nombre"
            :options="riders"
            :clearable="false"
            input-id="riderSelected"
          />
        </b-form-group>

        <b-form-group
          label="Nombre Ruta"
          label-for="rutaName"
        >
          <b-form-input
            id="rutaName"
            v-model="newRuta.nombre"
            placeholder="Nombre de la ruta"
            maxlength="24"
          />
        </b-form-group>

        <!-- <div>
          <label for="colorSelect">Color</label>
          <input
            id="colorSelect"
            v-model="newRuta.color"
            type="color"
          >
        </div> -->

        <b-form-group
          label="Color"
          label-for="colorSelect"
        >
          <b-form-input
            id="colorSelect"
            v-model="newRuta.color"
            type="color"
          />
        </b-form-group>

        <b-form-group
          label="Descripción"
          label-for="rutaDescription"
        >
          <b-form-input
            id="rutaDescription"
            v-model="newRuta.description"
            placeholder="(Opcional) Una descripción de la ruta"
            maxlength="150"
          />
        </b-form-group>

        <div>
          <label for="rutaNotas">Notas</label>
          <b-form-textarea
            id="rutaNotas"
            v-model="newRuta.notas"
            placeholder="(Opcional) Notas sobre esta ruta"
            rows="3"
          />
        </div>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            :disabled="!isPermitSave"
            @click="onSave"
          >
            Save
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  // BFormInvalidFeedback,
  // BFormInput,
  // BMedia,
  // BMediaBody,
  // BRow,
  // BCol,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { ref } from '@vue/composition-api'
// import { required, alphaNum } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    vSelect,
    // BFormInvalidFeedback,
    // BFormInput,
    // BMedia,
    // BMediaBody,
    // BRow,
    // BCol,

    // Form Validation
    // ValidationProvider,
    // ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRutaSidebarActive',
    event: 'update:is-add-new-ruta-sidebar-active',
  },
  props: {
    isAddNewRutaSidebarActive: {
      type: Boolean,
      required: true,
    },
    idruta: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // required,
      // alphaNum,
      newRuta: null,
      selectVehiculo: null,
      selectRider: null,
    }
  },
  computed: {
    riders() {
      return [
        {
          id: 'hq3v5ellzlZ0sfOH1qL0kfWfz8B2',
          nombre: 'Rider 1',
        },
        {
          id: 'amwYAHaUz6cAiQqq3yxNpeMx6Nz1',
          nombre: 'Rider 2',
        },
        {
          id: 'G36CsnCrfXeNjKmDZVOKptN7vE33',
          nombre: 'Rider 3',
        },
      ]
    },
    isPermitSave() {
      let result = false
      if (this.selectVehiculo && this.newRuta && this.newRuta?.nombre) result = true
      return result
    },
    vehiculos() {
      return this.$store.state.vehiculos.vehiculos
    },
    rutaModel() {
      return this.$store.getters['rutas/rutaModel']
    },
  },
  watch: {
    idruta() {
      this.initialize()
    },
  },
  methods: {
    initialize() {
      this.resetForm()
      this.newRuta = JSON.parse(JSON.stringify(this.rutaModel))
      this.newRuta.id = `${this.idruta}`
      this.newRuta.created_at = this.idruta
    },
    onSave() {
      this.newRuta.vehiculo_id = this.selectVehiculo.id
      this.newRuta.vehiculo_tipo = this.selectVehiculo.nombre
      this.newRuta.vehiculo_capacidad = this.selectVehiculo.capacidadUnidadesTransporte
      this.newRuta.rider_id = this.selectRider.id
      this.newRuta.rider_nombre = this.selectRider.nombre
      this.$store.dispatch('rutas/addRuta', this.newRuta)
      this.$emit('update:is-add-new-ruta-sidebar-active', false)
    },
    resetForm() {
      this.newRuta = null
      this.selectVehiculo = null
    },
    onSubmit() {},
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-email.scss";
#add-new-ruta-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
  form {
    overflow-x: hidden;
  }
  .txt-recortar {
    max-width: 90%;
  }
}
</style>
