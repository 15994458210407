<template>
  <b-modal
    id="pickingToRuta"
    ref="picking-pedidos-rutas"
    :visible="isPickingPedidosToRutas"
    title="Picking: Asignar Rutas"
    ok-title="Guardar"
    cancel-variant="outline-secondary"
    hide-footer
    centered
    size="lg"
    @change="(val) => $emit('update:is-picking-pedidos-to-rutas', val)"
  >
    <b-row class="match-height">
      <b-col
        v-for="ruta in rutas"
        :key="ruta.id"
        sm="4"
      >
        <b-card
          :title="ruta.nombre"
          :sub-title="`${ruta.vehiculo_tipo} - ${ruta.vehiculo_capacidad} UT`"
          :class="`text-center ${rutaSelected === ruta.id ? 'ruta-selected' : ''}`"
        >
          <vehiculo-icon
            :color="ruta.color"
            size="120"
          />
          <b-card-text>
            {{ ruta.description }}
          </b-card-text>
          <template #footer>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :disabled="rutaSelected === ruta.id"
              :variant="rutaSelected === ruta.id ? 'secondary' : 'outline-primary'"
              @click="saveChanges(ruta.id)"
            >
              Seleccionar
            </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal,
  BRow, BCol, BCardText,
  BCard, BButton,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import VehiculoIcon from '@/assets/images/icons/maps/VehiculoIcon.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // BButton,
    BModal,
    BRow,
    BCol,
    BCardText,
    // BCardActions,
    BCard,
    BButton,
    // BCardBody,
    // BCardTitle,
    // BCardSubTitle,
    // BLink,
    // BImg,
    // BFormGroup,
    // vSelect,

    VehiculoIcon,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isPickingPedidosToRutas',
    event: 'update:is-picking-pedidos-to-rutas',
  },
  props: {
    pedidos: {
      type: Array,
      required: true,
    },
    isPickingPedidosToRutas: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      rutaSelected: null,
    }
  },
  computed: {
    rutas() {
      const items = this.$store.state.rutas.rutas
      if (!items.length) return []
      return items.filter(r => r.status < 10 && r.active)
    },
    // rutaSelectedCalc() {
    //   const { pedidos } = this.$props
    //   let rutas = []
    //   for (let index = 0; index < pedidos.length; index += 1) {
    //     const pedido = pedidos[index]
    //     if (!pedido.ruta) {
    //       rutas = []
    //       break
    //     }
    //     if (pedido.ruta && !rutas.includes(pedido.ruta)) {
    //       rutas.push(pedido.ruta)
    //     }
    //   }
    //   return rutas.length === 1 ? rutas[0] : null
    // },
  },
  watch: {
    pedidos() {
      this.initialize()
    },
  },
  methods: {
    initialize() {
      this.rutaSelected = null
      if (this.pedidos.length === 1 && this.pedidos[0].ruta) {
        this.rutaSelected = this.pedidos[0].ruta
      }
    },
    saveChanges(ruta) {
      for (let index = 0; index < this.pedidos.length; index += 1) {
        const pedido = this.pedidos[index]
        console.log(pedido)
        const payload = {
          pedido,
          ruta,
        }
        console.log(payload)
        this.$store.dispatch('rutas/pedidoToRuta', payload)
        this.$emit('cierraVentana')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ruta-selected {
  background: #d6ebee;
}
</style>
