<template>
  <b-card-actions
    title="Orders"
    class="mb-0"
    action-collapse
  >
    <b-table
      id="listRutas"
      ref="rutasList"
      class="position-relative"
      :items="rutas"
      responsive
      :fields="rutasTableColumns"
      primary-key="id"
      :sort-by.sync="sortByRuta"
      show-empty
      empty-text="No matching records found"
      table-variant="none"
      :sort-desc.sync="isSortDirDescRuta"
    >

      <!-- Column: select -->
      <template #cell(select)="data">
        <b-form-checkbox
          :checked="isCheckedRuta(data.item.id)"
          @click.native.stop
          @change="$emit('updateRutasSelected', data.item.id)"
        />
      </template>

      <template #cell(id)="data">
        <div
          class="text-nowrap"
          @click="$emit('editRuta', data.item)"
        >
          {{ data.item.id }}
          <vehiculo-icon
            :color="rutaColorIcon(data.item.id)"
            size="36"
            class="ml-1"
          />
        </div>
      </template>

      <!-- Column: Pedidos -->
      <template #cell(pedidos)="data">
        <b-badge
          pill
          :variant="`light-${resolveRutaPedidos(data.item.id).color}`"
          class="text-capitalize"
        >
          {{ resolveRutaPedidos(data.item.id).value }}
        </b-badge>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveRutaStatusVariant(data.item.status).color}`"
          class="text-capitalize"
        >
          {{ resolveRutaStatusVariant(data.item.status).txt }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-button
            v-if="!resolveRutaPedidos(data.item.id).value"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            target="_blank"
            class="btn-icon"
            @click="deleteRuta(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
          <b-button
            v-if="data.item.calculated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-success"
            :href="data.item.calculated.link"
            target="_blank"
            class="btn-icon"
          >
            <feather-icon icon="MapIcon" />
          </b-button>
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="$emit('editRuta', data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item>
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item> -->
          </b-dropdown>
        </div>
      </template>

    </b-table>

    <!-- Paginación -->
    <!-- <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        />
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="itemsMostrarOrden.total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div> -->
  </b-card-actions>

</template>

<script>
// import preRutaFacke from '@/@fake-db/data/pre_ruta.json'
// import rutasOptimizadasFack from '@/@fake-db/data/rutas_optimizadas.json'
// import rutasSelectOptimizarFack from '@/@fake-db/data/rutas_select_optimizar.json'
import {
  BButton, BTable,
  BDropdown, BDropdownItem, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VehiculoIcon from '@/assets/images/icons/maps/VehiculoIcon.vue'
import usePedidos from './usePedidos'

export default {
  components: {
    BButton,
    BTable,
    BFormCheckbox,
    BCardActions,
    BBadge,
    BDropdown,
    BDropdownItem,
    VehiculoIcon,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    rutasSelected: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const {
      itemsMostrarOrden,
      sortBy,
      isSortDirDesc,
      perPage,
      currentPage,
      // pedidosSelected,
      // editPedido,
      // pickingVehiculo,
      sortByRuta,
      isSortDirDescRuta,

      rutaColorIcon,
      resolveRutaStatusVariant,
      rutasTableColumns,
      rutas,
      resolveRutaPedidos,
      editRuta,
      deleteRuta,
      // picking,
    } = usePedidos()

    const isCheckedRuta = rutaId => props.rutasSelected.includes(rutaId)

    return {
      itemsMostrarOrden,
      sortBy,
      isSortDirDesc,
      perPage,
      currentPage,
      // pedidosSelected,
      // editPedido,
      // pickingVehiculo,
      sortByRuta,
      isSortDirDescRuta,

      rutaColorIcon,
      resolveRutaStatusVariant,
      rutasTableColumns,
      // picking,
      isCheckedRuta,
      rutas,
      resolveRutaPedidos,
      editRuta,
      deleteRuta,
    }
  },

  watch: {
    pedidosSelected() {
      this.$root.$emit('bv::refresh::table', 'listRutas')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
