<template>
  <b-card-actions
    title="Filters"
    no-body
    class="mb-0"
    action-collapse
  >

    <!-- Filters -->
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Zona</label>
          <v-select
            v-model="zonaFilter"
            :options="zonaOptions"
            class="w-100"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Desde</label>
          <v-select
            v-model="rutaFilter"
            :options="rutaOptions"
            class="w-100"
          >
            <template #option="{ label, value }">
              <vehiculo-icon
                :color="rutaColorIcon(value)"
                size="24"
              />
              <span class="ml-1"> {{ label }}</span>
            </template>

          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Desde</label>
          <b-form-datepicker
            id="fecha-desde"
            v-model="fechaDesdeFiltros"
            locale="es-ES"
            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
            class="mb-1"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Hasta</label>
          <b-form-datepicker
            id="fecha-hasta"
            v-model="fechaHastaFiltros"
            locale="es-ES"
            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
            class="mb-1"
          />
        </b-col>
      </b-row>
    </b-card-body>

    <!-- Info -->
    <!-- <span class="text-info ml-3">{{ itemsMostrarOrden.totalBultos }} {{ itemsMostrarOrden.totalBultos !== 1 ? 'Bultos' : 'Bulto' }} en {{ itemsMostrarOrden.total }} {{ itemsMostrarOrden.total !== 1 ? 'Pedidos' : 'Pedido' }}</span> -->
  </b-card-actions>
</template>

<script>
import {
  BRow,
  BCol,
  BCardBody,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VehiculoIcon from '@/assets/images/icons/maps/VehiculoIcon.vue'
import usePedidos from './usePedidos'

export default {
  components: {
    BCardActions,
    BCardBody,
    BRow,
    BCol,
    BFormDatepicker,
    vSelect,
    VehiculoIcon,
  },
  setup() {
    const {
      zonaFilter,
      zonaOptions,
      rutaFilter,
      rutaOptions,
      rutaColorIcon,
      fechaDesde,
      fechaHasta,
    } = usePedidos()

    const fechaDesdeFiltros = ref(new Date(new Date(fechaDesde.value).getFullYear(), new Date(fechaDesde.value).getMonth(), new Date(fechaDesde.value).getDate()))
    const fechaHastaFiltros = ref(new Date(new Date(fechaHasta.value).getFullYear(), new Date(fechaHasta.value).getMonth(), new Date(fechaHasta.value).getDate()))

    // fechaDesdeFiltros.value = fechaDesde.value
    return {
      zonaFilter,
      zonaOptions,
      rutaFilter,
      rutaOptions,
      rutaColorIcon,
      fechaDesde,
      fechaHasta,
      fechaDesdeFiltros,
      fechaHastaFiltros,
    }
  },
  watch: {
    rutaFilter(newValue) {
      // console.log(newValue)
      const rutaF = newValue && newValue.value ? newValue.value : null
      this.$emit('updateRutaFilter', rutaF)
    },
    zonaFilter(newValue) {
      // console.log(newValue)
      const zonaF = newValue && newValue.value ? newValue.value : null
      this.$emit('updateZonaFilter', zonaF)
    },
    fechaDesdeFiltros(newValue) {
      // console.log(newValue)
      // console.log(this.fechaDesdeFiltros)
      const fecha = new Date(newValue).getTime()
      const contra = this.fechaHasta
      // console.log(`${fecha} > ${contra} => ${fecha > contra}`)
      if (fecha > contra) {
        // this.fechaDesdeFiltros = new Date(new Date(this.fechaDesde).getFullYear(), new Date(this.fechaDesde).getMonth(), new Date(this.fechaDesde).getDate())
        return
      }
      this.$store.commit('app/UPDATE_FECHA_DESDE', fecha)
    },
    fechaHastaFiltros(newValue) {
      // console.log(newValue)
      const fecha = new Date(newValue).getTime() + 86400000
      const contra = this.fechaDesde
      // console.log(`${fecha} < ${contra} => ${fecha > contra}`)
      if (fecha < contra) {
        // this.fechaHastaFiltros.value = ref(new Date(new Date(this.fechaHasta.value).getFullYear(), new Date(this.fechaHasta.value).getMonth(), new Date(this.fechaHasta.value).getDate()))
        return
      }
      this.$store.commit('app/UPDATE_FECHA_HASTA', fecha)
    },
  },
  mounted() {
    this.fechaDesdeFiltros.value = this.fechaDesde.value
    this.fechaHastaFiltros.value = this.fechaHasta.value
  },
}
</script>

<style>

</style>
